<template>
  <div>
    <CRow>
      <CCol sm="12">
        <UsersTable
          :items="users"
          hover
          striped
          border
          small
          fixed
          caption="Usuarios"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UsersTable from '../../components/users/UsersTable.vue'
import users from '../../services/users';
import store from '../../store'

export function initFacebookSdk() {
    window.addEventListener('message', (event) => {
      console.log(event);
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        console.log(data);

        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const {phone_number_id, waba_id} = data.data;
            console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
            // if user cancels the Embedded Signup flow
          } else if (data.event === 'CANCEL') {
            const {current_step} = data.data;
            console.warn("Cancel at ", current_step);
            // if user reports an error during the Embedded Signup flow
          } else if (data.event === 'ERROR') {
            const {error_message} = data.data;
            console.error("error ", error_message);
          }
        }
        document.getElementById("session-info-response").textContent = JSON.stringify(data, null, 2);
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    });

    return new Promise(resolve => {
        // wait for facebook sdk to initialize before starting the vue app
        window.fbAsyncInit = function () {
            FB.init({
                appId: "899633625354989",
                cookie: true,
                xfbml: true,
                version: 'v21.0'
            });

            FB.login(function(response) {
                if (response.authResponse) {
                console.log('Welcome!  Fetching your information.... ');
                FB.api('/me', function(response) {
                  console.log('Good to see you, ' + response.name + '.');
                });
                } else {
                console.log('User cancelled login or did not fully authorize.');
                }
            });
        };

        // load facebook sdk script
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}

export default {
  name: 'Users',
  components: { UsersTable },
  data: function () {
		return {
            users: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await users.get();

    if(response.type == "success"){
      this.users = response.data;
    }

    initFacebookSdk();

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    checkLoginState(){
      FB.getLoginStatus(function(response) {
        console.log(response);
      });
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
