<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-https"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal()">Nuevo Usuario</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 5"
        :dark="dark"
        pagination
      >
        <template #name="{item}"><td class="text-left">{{item.name}}<br /><span style="font-size: 12px; font-weight: bold;">{{item.job_position}}</span></td></template>
        <template #role="{item}"><td class="text-center">{{item.role}}</td></template>
        <template #phone="{item}"><td class="text-center">{{item.phone ? item.phone : item.email}}</td></template>
        <template #actions="{item}">
          <td class="table_actions">
            <CButton v-if="item.role_code != 'administrator'" color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
            <CButton v-if="item.role_code != 'administrator'" color="danger" size="sm" @click="deleteModal(item)"><CIcon name="cil-trash"/></CButton>
          </td>
        </template>
      </CDataTable>
    </CCardBody>
    <UserModal ref="userModal" @store="storeProcess" @update="updateProcess"></UserModal>
    <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
  </CCard>
</template>

<script>
import UserModal from './UserModal.vue'
import DeleteModal from '../global/DeleteModal.vue'
import ws from '../../services/users';
import store from '../../store'

export default {
  name: 'UsersTable',
  components: { UserModal, DeleteModal },
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return [
          {key: 'name', label: 'Nombre'},
          {key: 'phone', label: 'Cuenta (Whatsapp)'},
          {key: 'role', label: 'Rol de Usuario'},
          {key: 'actions', label: 'Acciones', class: 'actions' },
        ]
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
    }
  },
  methods: {
    storeModal () {
      this.$refs.userModal.storeModal();
    },
    updateModal (item) {
      this.$refs.userModal.updateModal(item);
    },
    deleteModal (item) {
      this.$refs.deleteModal.show("Eliminar Usuario", item.id, item.name);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async deleteProcess(id) {
      let response = await ws.delete({id: id});
      
      if(response.type == "success"){
        this.$emit("refresh", response.data );
      }

      this.showToast(response.type, response.message);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
